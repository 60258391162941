import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { IImage } from './types'

const Image = ({ file, gatsbyImageData, altText, imageStyles }: IImage) => {
  if (
    file?.contentType === 'image/gif' ||
    file?.contentType === 'image/svg+xml'
  ) {
    return (
      <img
        className={imageStyles || ''}
        src={file.url}
        alt={altText ? altText : ' '}
      />
    )
  }

  return (
    <GatsbyImage
      className={imageStyles || ''}
      image={getImage(gatsbyImageData)}
      alt={altText ? altText : ' '}
    />
  )
}

export default Image
