import React, { ReactElement } from 'react'
import { BLOCKS, INLINES, MARKS, Node } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { filterEmptyNodes } from './RichText.utils'
import { IRichText } from './types'
import LinkConverter from '../LinkConverter'

// MUI
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import SvgIcon from '@material-ui/core/SvgIcon'
import { iconsMap } from '@tdcerhverv/parrotfish/dist/icons/icons'
import Image from '../Image'
import useMobileDisplay from '../../hooks/useMobileDisplay'
import getIdFromString from '../../utils/getIdFromString'
import CallToAction from '../CallToAction'

const useStyles = makeStyles(theme => {
  return {
    root: {
      '& .MuiButton-root': {
        marginRight: 16,
        '&:last-child': {
          marginRight: 0,
        },
        [theme.breakpoints.down('sm')]: {
          marginBottom: 16,
        },
      },
      '& ul': {
        padding: 0,
        paddingLeft: 18,
        '& li': {
          marginBottom: 16,
          '& p': {
            margin: 0,
          },
        },
      },
    },
    h2: {
      scrollMarginTop: '80px',
      marginBottom: '.5em',
      wordBreak: 'break-word',
      '&:only-child': {
        marginTop: -5,
      },
      '&:first-child': {
        marginTop: 0,
      },
    },
    h3: {
      wordBreak: 'break-word',
      marginBottom: '.7em',
    },
    h4: {
      wordBreak: 'break-word',
      marginBottom: '1em',
    },
    h5: {
      wordBreak: 'break-word',
      marginBottom: '1em',
    },
    h6: {
      wordBreak: 'break-word',
      marginBottom: '1em',
    },
    divider: {
      marginBottom: '1em',
    },
    underline: {
      textDecoration: 'underline',
    },
    linkIcon: {
      width: 11,
      height: 11,
      marginLeft: 11,
    },
    table: {
      '& table': {
        width: '100%',
        borderColor: 'white',
        '& p': {
          marginBottom: 0,
        },
        '& th': {
          padding: 5,
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white,
        },
        '& td': {
          padding: 5,
        },
        '& tr:nth-child(even) td': {
          backgroundColor: theme.palette.grey[200],
        },
        [theme.breakpoints.down('xs')]: {
          display: 'block',
          overflowX: 'auto',
        },
      },
    },
  }
})

const urlStartswith = (url: string, identifier: string) => {
  const protocolRegex = new RegExp('^http[s]?://' + identifier, 'i')

  return protocolRegex.test(url)
}

const removeLastEmptyNode = (raw: string) => {
  /**
   * Removes the last node if the value is empty, preventing a breaktag at the end of the content.
   */
  const parsedData = JSON.parse(raw)
  if (!parsedData.content) return
  const newContent = parsedData.content.filter((item, index) => {
    if (item.content.length < 1) return item
    if (parsedData.content.length - 1 === index) {
      return item.content.find(content => content.value !== '')
    }
    return item
  })
  const dataArray = {
    ...parsedData,
    content: newContent,
  }
  return JSON.stringify(dataArray) as string
}

const RichText = ({
  raw,
  references,
  mapping,
  className,
  parentModule,
}: IRichText): ReactElement => {
  const classes = useStyles()
  const isMobile = useMobileDisplay('sm')
  const filteredRaw = removeLastEmptyNode(raw)

  const options = {
    renderMark: {
      [MARKS.BOLD]: (text: string) => <strong>{text}</strong>, // replaces <b>
      [MARKS.ITALIC]: (text: string) => <em>{text}</em>, //replaces <i>
      [MARKS.UNDERLINE]: (text: string) => (
        <Typography component="span" className={classes.underline}>
          {text}
        </Typography>
      ),
    },
    renderNode: {
      [BLOCKS.HEADING_1]: (node: Node, text: string) => {
        const element = (
          <Typography component="h2" variant={isMobile ? 'h3' : 'h1'}>
            {text}
          </Typography>
        )
        return filterEmptyNodes(node, element)
      },
      [BLOCKS.HEADING_2]: (node: Node, text: Array<any>) => {
        const richTextArray = text[0]
        const headingText = richTextArray?.['props']
          ? richTextArray?.['props']?.children[1]
          : richTextArray?.filter(
              element => typeof element === 'string' && element.length > 0,
            )[0]
        const id = headingText && getIdFromString(headingText)
        const element = (
          <Typography
            component="h2"
            variant={mapping?.h2 || 'h2'}
            className={classes.h2}
            data-scroll-id={id}
          >
            {text}
          </Typography>
        )
        return filterEmptyNodes(node, element)
      },
      [BLOCKS.HEADING_3]: (node: Node, text: string) => {
        const element = (
          <Typography
            component="h3"
            variant={mapping?.h3 || 'h3'}
            className={classes.h3}
          >
            {text}
          </Typography>
        )
        return filterEmptyNodes(node, element)
      },
      // h4 -> h6 are awaiting design for what they should render as.
      [BLOCKS.HEADING_4]: (node: Node, text: string) => {
        const element = (
          <Typography
            className={classes.h4}
            component="h4"
            variant={mapping?.h4 || 'h4'}
          >
            {text}
          </Typography>
        )
        return filterEmptyNodes(node, element)
      },
      [BLOCKS.HEADING_5]: (node: Node, text: string) => {
        const element = (
          <Typography
            className={classes.h5}
            component="h5"
            variant={mapping?.h5 || 'h5'}
          >
            {text}
          </Typography>
        )
        return filterEmptyNodes(node, element)
      },
      [BLOCKS.HEADING_6]: (node: Node, text: string) => {
        const element = (
          <Typography
            className={classes.h6}
            component="h6"
            variant={mapping?.h6 || 'h6'}
          >
            {text}
          </Typography>
        )
        return filterEmptyNodes(node, element)
      },
      [BLOCKS.HR]: () => <Divider className={classes.divider} />,
      [BLOCKS.PARAGRAPH]: (node: Node, text: string) => {
        const element = (
          <Typography
            variant={'body1'}
            style={{
              fontSize: '14px',
              marginTop: '13px',
              marginBottom: '13px',
            }}
            paragraph
          >
            {text}
          </Typography>
        )
        return filterEmptyNodes(node, element)
      },
      [BLOCKS.TABLE]: (node: Node, children) => (
        <div className={classes.table}>
          <table>
            <tbody>{children}</tbody>
          </table>
        </div>
      ),
      [BLOCKS.TABLE_HEADER_CELL]: (node: Node, children) => (
        <th scope="col">{children}</th>
      ),
      [BLOCKS.TABLE_ROW]: (node: Node, children) => <tr>{children}</tr>,
      [BLOCKS.TABLE_CELL]: (node: Node, children) => (
        <td scope="col">{children}</td>
      ),
      [INLINES.HYPERLINK]: (node: Node, text: string) => {
        const url = node.data?.uri
        const isTdc = urlStartswith(url, 'tdc.dk')
        if (!url) {
          throw new Error('A Hyperlink must have a url provided')
        }
        const element = (
          <>
            <LinkConverter
              {...(!isTdc && { target: '_blank' })}
              linkHref={url}
              isTooltip={true}
            >
              {text}
            </LinkConverter>
            {mapping?.linkIcon && (
              <SvgIcon
                className={classes.linkIcon}
                component={iconsMap['External_link']}
                viewBox="0 0 48 48"
              />
            )}
          </>
        )
        return filterEmptyNodes(node, element)
      },
      [INLINES.ENTRY_HYPERLINK]: (node: Node, children: string) => {
        return (
          <LinkConverter linkHref={`/${node?.data?.target?.slug}`}>
            {children}
          </LinkConverter>
        )
      },
      [INLINES.ASSET_HYPERLINK]: (node: Node, children: string) => {
        const { file } = node?.data?.target
        if (!file) return null
        return (
          <LinkConverter linkHref={file.url} target="_blank">
            {children}
          </LinkConverter>
        )
      },
      [INLINES.EMBEDDED_ENTRY]: (node: Node) => {
        const target = node.data.target
        if (!target) {
          console.warn(`target is null, check GRAPHQL fragment`)
          return null
        }
        const { contentful_id: _id, ...props } = target
        return <CallToAction {...props} />
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node: Node) => {
        const target = node.data.target
        if (!target) {
          console.warn(`target is null, check GRAPHQL fragment`)
          return null
        }
        const { contentful_id: _id, ...props } = target
        return <CallToAction {...props} />
      },
      [BLOCKS.EMBEDDED_ASSET]: (node: Node) => {
        //For now, we assume that if the file is not JSON, that it is an image.
        return <Image {...node?.data?.target} />
      },
    },
    renderText: (text: string) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  }

  return (
    <Box className={`${classes.root} ${className || ''}`}>
      {renderRichText({ raw: filteredRaw, references }, options)}
    </Box>
  )
}

export default RichText
