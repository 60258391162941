import React from 'react'
import { ICallToAction } from './'
import { Link } from 'gatsby'
import { iconsMap } from '@tdcerhverv/parrotfish/dist/icons/icons'

// MUI
import Button from '@material-ui/core/Button'

// TDC Theme
import { Variants } from '@tdcerhverv/mui-theme/dist/theme/global/variants'
import { SvgIcon } from '@material-ui/core'

const CallToAction = ({
  buttonText,
  linkTarget,
  trackingId: id,
  trackingIdModule,
  internal: _internal,
  contentful_id: _contentful_id,
  icon,
  inverted = false,
  variant,
  color,
  ...props
}: ICallToAction) => {
  const invertedClassName =
    inverted && variant === 'outlined' ? Variants.Button.Inverted : ''

  const getTrackingId = () => {
    let trackingId = id && `cta-${id}`
    trackingId && trackingIdModule && (trackingId += `__${trackingIdModule}`)
    return trackingId
  }

  const colorMap = new Map()

  colorMap.set('red', 'secondary')
  colorMap.set('green', 'primary')

  return (
    <Button
      {...(linkTarget && !linkTarget.startsWith('http')
        ? { component: Link, to: linkTarget }
        : { href: linkTarget })}
      color={color ? colorMap.get(color) : 'default'}
      className={
        !!color && (color as string) === 'orange'
          ? `${Variants.Button.Brand}`
          : `${invertedClassName}`
      }
      id={getTrackingId() || null}
      {...props}
      startIcon={
        icon && (
          <SvgIcon
            component={iconsMap[icon?.iconImage?.file?.fileName?.slice(0, -4)]}
            viewBox="0 0 48 48"
          />
        )
      }
    >
      {buttonText}
    </Button>
  )
}

export default CallToAction
