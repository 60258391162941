import React, { ReactElement } from 'react'
import { Node } from '@contentful/rich-text-types'

/**
 * Contentful often adds extra nodes with no values in a Rich Text. This function replaces those with ReactFragments so that we don't pollute the DOM and mess up spacing on the page
 */
export function filterEmptyNodes(
  node: Node,
  outputElement: ReactElement,
): ReactElement {
  const emptyTextValues = ['', '\r', '\n']

  // * typescript compiler sometimes says 'Property 'content' does not exist on type 'Node'.ts(2339)'
  // * don't believe its lies, it exists.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const { content } = node
  const emptyContent = content.filter(({ value }) => {
    return emptyTextValues.includes(value)
  })
  // if content and emptyContent are the same length, then there is nothing other than whitespace in the Node and it should be removed
  const isEmptyNode = content.length === emptyContent.length
  return isEmptyNode ? <br /> : outputElement
}
